<template>
  <v-pagination
    v-model="currentPage"
    :length="lastPage"
    color="primary"
    class="my-5"
  ></v-pagination>
</template>

<script>

export default {
  props: ['store', 'collection'],
  computed: {
    currentPage: {
      get() {
        return this.$store.state[this.store][this.collection].current_page
      },
      set(val) {
        return this.$store.commit(`${this.store}/coursePageMutation`, val)
      }
    },
    lastPage: {
      get() {
        return this.$store.state[this.store][this.collection].last_page
      }
    }
  },
  methods: {
    paginate(pageNumber) {
      this.$store.dispatch(`${this.store}/getCoursesAction`,{
        page: pageNumber,
        query: this.$store.state[this.store].query,
        date: this.$store.state[this.store].date,
        count: this.$store.state[this.store].count,
        status: this.$store.state[this.store].status,
      })
    }
  },
  watch: {
    currentPage(val) {
      this.paginate(val)
    }
  }
}
</script>